import request from './request';

export function getProductList(params){
    return request({
        url: 'product/getProductList',
        method: 'post',
        data: params
    });
}

export function getSiteListByPlatForm(params){
    return request({
        url: 'product/getSiteListByPlatForm',
        method: 'post',
        data: params
    });
}


export function addProudct(params){
    return request({
        url: 'product/addProduct',
        method: 'post',
        data: params
    });
}

export function updateProudct(params){
    return request({
        url: 'product/updateProduct',
        method: 'post',
        data: params
    });
}

export function getProduct(params){
    return request({
        url: 'product/getProduct',
        method: 'post',
        data: params
    });
}

export function getCategory(params){
    return request({
        url: 'product/getCategory',
        method: 'post',
        data: params
    });
}

export function downLoadPlatProduct(params){
    return request({
        url: 'product/downLoadPlatProduct',
        method: 'post',
        data: params
    });
}

export function deleteProduct(params){
    return request({
        url: 'product/deleteProduct',
        method: 'post',
        data: params
    });
}

export function getProductSyncList(params){
    return request({
        url: 'product/getProductSyncList',
        method: 'post',
        data: params
    });
}

<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>

            <a href="javascript:;" class="font-size-sm text-dark-50 text-hover-primary" style="line-height:70px" @click="cancelForm()">
                <i class="la la-times font-size-h4"></i>
            </a>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <KTDatatable
                        v-bind:list="list"
                        v-bind:column="column"
                        v-bind:showSelect="false"
                        v-bind:showPagination="true"
                        v-bind:pageSize="page.limit"
                        v-bind:pageCount="page.pageCount"
                        v-bind:total="page.total"
                        @operateHandler="operateHandler"
                        @pagechangeHandler="pagechangeHandler"
                ></KTDatatable>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import {getProductSyncList} from '@/api/product';

    export default {
        name: "product_sync",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            _this.searchList();
        },
        components :{
            KTDatatable
        },
        data() {
            return {
                list: [],
                column: [{
                    field: 'type',
                    title: '同步类型',
                    formatter: function(row) {
                        if(row.type == 1) {
                            return '上传';
                        } else {
                            return '下载';
                        }
                    }
                },{
                    field: 'creator',
                    title: '同步人',
                }, {
                    field: 'create_time',
                    title: '同步时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                }],
                search_form: {
                    product_id: this.dialog_data.form_data.id,
                    site_id: this.dialog_data.form_data.site_id
                },
                page: {
                    currPage: 1,
                    limit: 8,
                    pageCount:0,
                    total:0,
                },
            }
        },
        mounted() {

        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getProductSyncList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            operateHandler(clazz, row) {
                var _this = this;

            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
        }
    }
</script>

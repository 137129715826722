<template>
    <v-app style="background: transparent" :class="{}">
        <template v-if="route_children">
            <router-view></router-view>
        </template>
        <template v-else>
            <!-- begin:: Content Head -->
            <KTSubheader
                    v-if="nav.subheaderDisplay"
                    v-bind:breadcrumbs="nav.breadcrumbs"
                    v-bind:title="nav.pageTitle"
            />
            <!-- end:: Content Head -->

            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container">
                    <div class="card condition" role="alert">
                        <div class="box-card">
                            <p>平台渠道：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.plat_form==''?'active':'']"
                                        @click="search_form.plat_form=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in platform_list"
                                    v-bind:key="item.value">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.plat_form==item.value?'active':'']"
                                       @click="search_form.plat_form=item.value"
                                    >{{item.text}}</a>
                                </li>

                            </ul>
                        </div>

                        <div class="box-card">
                            <p>站点店铺：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.site_id==''?'active':'']"
                                       @click="search_form.site_id=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in site_list"
                                    v-bind:key="item.id">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.site_id==item.id?'active':'']"
                                       @click="search_form.site_id=item.id"
                                    >{{item.site_name}}</a>
                                </li>


                            </ul>
                        </div>

                    </div>


                    <!--begin::Card-->
                    <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                                <h3 class="card-label">产品列表
                                    <span class="d-block text-muted pt-2 font-size-sm"></span>
                                </h3>
                            </div>
                            <div class="card-toolbar">


                                <!--begin::Dropdown-->
                                <div class="dropdown dropdown-inline mr-2">
                                    <button type="button"
                                            class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                                            @click="syncMenuShow = !syncMenuShow"
                                    >
												产品同步
                                    </button>
                                    <div v-if="syncMenuShow" class="dropdown-menu dropdown-menu-sm dropdown-menu-right" style="display:block;">
                                        <ul class="navi flex-column navi-hover" style="padding-left:0;">
                                            <li class="navi-item"
                                                v-for="site in site_list"
                                                :key="site.id"
                                                @click="syncProduct(site.id)"
                                            >
                                                <a href="javascript:void(0);" class="navi-link">
                                                    <span class="navi-text">{{site.site_name}}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>



                                <!--begin::Button-->
                                <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addProduct()">
                                    新增产品
                                </a>
                                <!--end::Button-->
                            </div>
                        </div>
                        <div class="card-body">
                            <!--begin: Search Form-->

                            <!--begin::Search Form-->
                            <div class="mb-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-9 col-xl-8">
                                        <div class="row align-items-center">
                                            <div class="col-md-4 my-2 my-md-0">
                                                <div class="input-icon">
                                                    <input type="text" class="form-control" placeholder="产品名称" v-model="search_form.product_title">
                                                    <span>
                                                    <i class="flaticon2-search-1 text-muted"></i>
                                                </span>
                                                </div>
                                            </div>
                                            <a href="javascript:;" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!--end::Search Form-->
                            <!--end: Search Form-->


                            <KTDatatable
                                    v-bind:list="list"
                                    v-bind:column="column"
                                    v-bind:showSelect="true"
                                    v-bind:showPagination="true"
                                    v-bind:pageSize="page.limit"
                                    v-bind:pageCount="page.pageCount"
                                    v-bind:total="page.total"
                                    @operateHandler="operateHandler"
                                    @pagechangeHandler="pagechangeHandler"
                            ></KTDatatable>

                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Container-->
            </div>

            <v-dialog v-model="dialog_show" persistent max-width="600px">
                <component
                        :key="dialog_id"
                        :title="dialog_title"
                        :visible="dialog_show"
                        :is="dialog_view"
                        :dialog_data="dialog_data"
                        @opeareResultHandler="opeareResultHandler"></component>
            </v-dialog>
        </template>
    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";

    import KTUtil from "@/assets/js/components/util";
    import {getProductList, getSiteListByPlatForm, downLoadPlatProduct, deleteProduct} from '@/api/product';
    import Swal from "sweetalert2";
    import productSync from '@/view/pages/product/ProductSync';
    import {
        ADD_BODY_CLASSNAME,
        REMOVE_BODY_CLASSNAME
    } from "@/core/services/store/htmlclass.module.js";
    export default {
        name: "product_view",
        components: {
            KTSubheader,
            KTDatatable
        },
        created: function(){
            var _this = this;

            //判断路由
            if('/' + this.$route.name == this.$route.path){
                _this.searchList();
            } else {
                _this.route_children = true;
            }
            _this.getSiteList();

        },
        data() {
            return {
                route_children: false,
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/site', 'title':'产品管理'}],
                    pageTitle: '产品'
                },
                list: [],
                column: [{
                    field: 'images',
                    title: '产品图片',
                    width: 90,
                    formatter: function(row) {
                        if(row.images[0] && row.images[0].image_url){
                            return '<div class="symbol symbol-80 flex-shrink-0">' +
                                    '<div class="symbol-label" style="background-image:url('+ row.images[0].image_url +')"></div>' +
                                '</div>';
                        } else {
                            return '';
                        }

                    }
                },{
                    field: 'product_title',
                    title: '产品名称',
                    width: 250,
                    formatter: function(row) {
                        return '<div class="ml-2">' +
                            '<a href="'+ row.product_url +'" target="_blank" class="noevent text-dark-75 font-weight-bold line-height-sm text-hover-primary">'+ row.product_title +'</a>' +
                            '</div>';
                    }
                },{
                    field: 'category_name',
                    title: '产品类型',
                },{
                    field: 'site_name',
                    title: '所属站点'
                },{
                    field: 'creator',
                    title: '创建人'

                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    width: 150,
                    formatter: function(row){

                        let html = '' +
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="修改">'+
                            '<i class="la la-edit icon-lg"></i>'+
                            '</a>';
                        /*
                        html +=  '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                            '<i class="la la-trash-o icon-lg"></i>'+
                            '</a>';
                        */

                        html +=  '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="同步记录">'+
                            '<i class="la la-clipboard-list icon-lg"></i>'+
                            '</a>';
                        return html;
                    }
                }],
                search_form: {
                    product_title: '',
                    plat_form: '',
                    site_id: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null,
                platform_list: KTUtil.getPlatForm(),
                site_list: [],
                syncMenuShow: false
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if('/' + this.$route.name == this.$route.path){
                        this.route_children = false;
                    } else {
                        this.route_children = true;
                    }
                    this.searchList();
                },
                deep: true
            },
            'search_form.plat_form': function(val){
                var _this = this;
                _this.getSiteList();
            },
            search_form: {
                handler (val, oldval) {
                    this.searchList();
                },
                deep: true
            }
        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getProductList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            getSiteList() {
                var _this = this;
                getSiteListByPlatForm({'site_platform': _this.search_form.plat_form}).then(function(res) {
                    if(res && res.response){
                        _this.site_list = res.response;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-edit') > -1){
                    this.$router.push({
                        path: '/product/updateproduct',
                        query: {
                            'id': row.id
                        }
                    });
                } else if(clazz.indexOf('la-trash-o') > -1){

                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteProduct({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                } else if(clazz.indexOf('la-clipboard-list') > -1){
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '产品同步记录';
                    this.dialog_view = productSync;
                    this.dialog_data = {
                        form_data: row
                    };
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addProduct() {
                this.$router.push({
                    path: '/product/addproduct'
                });
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            },
            syncProduct(site_id) {
                var _this = this;
                _this.syncMenuShow = false;

                Swal.fire({
                    title: '',
                    text: '请选择产品同步类型,本地同步:将平台产品数据同步到本地;平台同步：将本地产品数据发布到平台',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#0BB7AF",
                    cancelButtonColor: "#0BB7AF",
                    cancelButtonText: "本地同步",
                    confirmButtonText: "平台同步",
                    heightAuto: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        KTUtil.toast(_this, '结果提醒', '暂未开放', 'danger');
                    } else {

                        _this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                        downLoadPlatProduct({'site_id': site_id}).then(function(res){
                            if(res && res.response) {
                                KTUtil.toast(_this, '结果提醒', res.response, 'success');
                            }
                            _this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        });
                    }
                });
            }
        }
    }
</script>
